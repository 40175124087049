import React from "react";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";

import WrapperContainers from "../../components/WrapperContainer";
import GreenH2 from "../../components/Typography/H2/GreenH2";
import P from "../../components/Typography/P/P";

const Story = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1]} p={3}>
        <CenterGreen>Our Commitment to sustainability</CenterGreen>
        <P>
          Here at Advance Manufacturers Co., Ltd, we are committed to reducing
          our carbon footprint, promoting social responsibility and working to
          find new ways to contribute in nurturing the environment. From our
          ethical sourcing to our sustainable manufacturing and packaging,
          environmental awareness is at the core of our identity. With us,
          masterful production and sustainability exist side by side, redefining
          industry standards
        </P>
      </Box>
    </Flex>
  </Section>
);

export default Story;
const CenterGreen = styled(GreenH2)`
  text-align: center;
`;
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`;
