import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import WrapperContainers from "../../components/WrapperContainer";
import P from "../../components/Typography/P/P";

import GreenH2 from "../../components/Typography/H2/GreenH2";
const Waste = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[2, 2, 1]}>
        <MotivationImg src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632643387/About-us/About-our-philosophy_lb49mk.png" />
      </Box>
      <Box
        width={[1, 1, 1 / 2]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[1, 1, 2]}
        pt={[3, 3, 5]}
      >
        <GreenH2>Waste Management &amp; Management of Raw Materials</GreenH2>
        <P>
          We minimize waste in our production processes by carefully planned
          production steps. We review our processes extensively on a monthly
          basis to ensure efficiency and safety in waste treatment is at the
          highest standard
        </P>{" "}
        <P>
          It is essential to monitor and implement checks for safe management of
          left over raw materials. For example, scrap metal from production
          processes, excess wax from waxing department, rubber from wax moulds,
          and the copper rods and wires used in plating department are collected
          and recycled by local specialized facilities.
        </P>
      </Box>
    </Flex>
  </Section>
);

export default Waste;
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`;
const MotivationImg = styled.img`
  width: 100%;
  height: auto;
`;
