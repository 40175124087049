import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import WrapperContainers from "../../components/WrapperContainer";
import GreenH2 from "../../components/Typography/H2/GreenH2";

import P from "../../components/Typography/P/P";

const Responsible = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box
        width={[1, 1, 1 / 2]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[2, 2, 1]}
        pt={[3, 3, 5]}
      >
        <GreenH2>Transparent and Responsible Sourcing</GreenH2>

        <P>
          We offer stones that are ethically sourced and certified conflict-free
          by trusted suppliers. Lab-Grown Diamonds are also available as an
          additional sustainable option Biodegradable polybags made from plant
          based sources are available in all sizes
        </P>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[1, 1, 2]}>
        <MotivationImg src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632643897/About-us/team-work-2_omlouh.png" />
      </Box>
    </Flex>
  </Section>
);

export default Responsible;
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
  background-color: ${({ theme }) => theme.greySection};
`;
const MotivationImg = styled.img`
  width: 100%;
  height: auto;
`;
