import React from "react";
import { Flex, Box } from "reflexbox";
import styled from "styled-components";
import { Link } from "gatsby";
import WrapperContainers from "../../components/WrapperContainer";
import GreenH2 from "../../components/Typography/H2/GreenH2";
import P from "../../components/Typography/P/P";

const OurCertifications = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1]} p={3}>
        <CenterGreen>Our Certifications </CenterGreen>
      </Box>
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[2, 2, 1]}>
        <ResizeImg
          src="https://res.cloudinary.com/denq2jckc/image/upload/v1690975030/sustainability/0000_4686_Certified_Member_Logo_EU_US1_tvuqqq.jpg"
          alt="responsible jewellery certified member Advance Manufacture"
        />
      </Box>
      <Box  width={[1, 1, 1 / 2]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[1, 1, 2]}
       >
        <P>
          We are certified members of the Responsible Jewellery Council (RJC),
          aligning with their comprehensive sustainability standards that spans
          the entire global jewellery and watch industry supply chain, from
          mining to retail. As certified members, we actively leverage the RJC's
          invaluable technical expertise, support, and educational resources.
          Our association with RJC reflects our unwavering
          commitment to responsible and sustainable practices within the
          jewellery manufacturing sphere. For a more in-depth understanding of
          our commitment to sustainability, we invite you to visit{" "}
          <Nofollow href="https://www.responsiblejewellery.com" rel="noopener noreferrer nofollow"target="_blank">
            www.responsiblejewellery.com
          </Nofollow><br/>
          Our official certification can be downloaded below and is also visible on the RJC website.
        </P>
      </Box>
    </Flex>
  </Section>
);

export default OurCertifications;
const CenterGreen = styled(GreenH2)`
  text-align: center;
`;
const Section = styled(WrapperContainers)`
  position: relative;  padding: 0 10vw;
  

`;
const ResizeImg = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: 600px) {
    width: 23vw;
    height: auto;
  }
`;
const Nofollow = styled(Link)`
text-decoration: none;color:#6D6D6B;
`