import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import WrapperContainers from "../../components/WrapperContainer";
import P from "../../components/Typography/P/P";

import GreenH2 from "../../components/Typography/H2/GreenH2";
const SocialResponsability = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box width={[1, 1, 1 / 2]} p={[3, 2, 5]} order={[2, 2, 1]}>
        <MotivationImg src="https://res.cloudinary.com/denq2jckc/image/upload/q_auto,w_auto/v1632643387/About-us/About-our-philosophy_lb49mk.png" />
      </Box>
      <Box
        width={[1, 1, 1 / 2]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[1, 1, 2]}
        pt={[3, 3, 5]}
      >
        <GreenH2>Our Community &amp; Social Responsibility</GreenH2>
        <P>
          To guarantee that all employees receive fair treatment at all times,
          we have strict policies in place. We employ safe working conditions,
          offer living wages that are fair, and never use child labor in the
          manufacture of our jewelry. Additionally, we maintain a very open
          workplace where all employees are encouraged to express themselves,
          ask questions, and become knowledgeable about every part of the
          business.
        </P>{" "}
        <P>
          We believe that as a community, women empower everyone around them,
          promoting collaboration, fairness and equality which is why the
          workforce at Advance is made of more than 75% women. We encourage all
          of our employees to challenge themselves and give them the
          opportunities to grow in our company.
        </P>
      </Box>
    </Flex>
  </Section>
);

export default SocialResponsability;
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
`;
const MotivationImg = styled.img`
  width: 100%;
  height: auto;
`;
