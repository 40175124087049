import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Commitment from "../containers/Sustainability/Commitment"
import OurCertifications from "../containers/Sustainability/OurCertifications"
import StyledSustainBg from "../containers/Sustainability/Hero/SustainHero"
import Responsible from "../containers/Sustainability/Responsible"
import SocialResponsability from "../containers/Sustainability/SocialResponsability"
import Waste from "../containers/Sustainability/Waste"
import Acreditation from "../containers/Sustainability/Acreditations"
const Sustainability = () => (
  <Layout>
    {" "}
    <Seo title="About Us" />
    <StyledSustainBg />
    <Commitment />
    <OurCertifications/>
    <Waste />
    <Responsible />
    <SocialResponsability />
    <Acreditation />
  </Layout>
)
export default Sustainability
