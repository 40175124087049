import React from "react";
import styled from "styled-components";
import { Flex, Box } from "reflexbox/styled-components";
import { Link } from "gatsby";
import WrapperContainers from "../../components/WrapperContainer";
import GreenH2 from "../../components/Typography/H2/GreenH2";

import "../../../node_modules/react-medium-image-zoom/dist/styles.css";

const Acreditation = () => (
  <Section>
    <Flex flexWrap="wrap">
      <Box
        width={[1, 1, 1]}
        p={[3, 2, 3]}
        pr={[0, 0, 2]}
        order={[1, 1, 1]}
        pt={[3, 3, 5]}
      >
        <CenterGreen>
          View our Responsible Business Practices Policies
        </CenterGreen>
        <Li>
          <Nofollow
            href="https://www.responsiblejewellery.com/member/advance-manufacturers-co-ltd"
            rel="noopener noreferrer nofollow"
            target="_blank" alt="Official RJC Certification advance manufacturers co ltd"
          >
            Official RJC Certification
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/kko71gy4i8ucw89/A1-RJC%20Policy.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Responsible Business Practices Policy
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/w9okk0fiknukc72/A2-Supply%20Chain.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Supply Chain Policy
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/hekfup0jlta2iuw/A3-Grievance.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Grievance Mechanism Procedure
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/cvv4oxyzvj9kjyr/A4-Conflict%20of%20Mineral.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Conflict of Mineral Policy
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/v2cnu7ydgwe6z3r/B3-%E0%B9%81%E0%B8%95%E0%B9%88%E0%B8%87%E0%B8%95%E0%B8%B1%E0%B9%89%E0%B8%87%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B9%81%E0%B8%97%E0%B8%99.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Official Appointment of Compliance Manager Responsible for KYC & OECD Processes
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/1a80cbgy13hisms/D1-Annual%20OECD%20Report%20_3Feb23.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            OECD and Due Diligence Annual Report for 2022
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/uf6a0jrcancuv4g/Annual%20Objective%26Target%202023.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Annual Objective & Targets for 2023
          </Nofollow>
        </Li>
        <Li>
          <Nofollow
            href="https://www.dropbox.com/s/z5jpz065c6wblqt/A6-ADV%20Energy%20Conservation%20Policy.pdf?dl=0"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            Energy Conservation Policy
          </Nofollow>
        </Li>
      </Box>
    </Flex>
  </Section>
);

export default Acreditation;
const Section = styled(WrapperContainers)`
  padding: 5vh 10vw;
  position: relative;
  background-color: ${({ theme }) => theme.greySection};
`;

const CenterGreen = styled(GreenH2)`
  text-align: center;
`;

const Nofollow = styled(Link)`
  text-decoration: none;

  font-size: calc(0.8rem + 0.6vw);
  color: #6d6d6b;
  font-family: "Celias";
  text-transform: capitalize;
  line-height: 1.5;

  :hover {
    color: #29d9d5;
  }
`;
const Li = styled.li`
  padding-top: 22px;
`;
